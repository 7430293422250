document.addEventListener("turbolinks:load", function() {

  function update_checked_tire_count() {
    var checked_tires = document.querySelectorAll('.pp-tire-input:checked').length
    document.querySelector('.pp-checked-tires-count').innerHTML = checked_tires
  }

  $('.ppd-interventions-edit-quote input[type="file"]').on('change', function(e){
    $(this).closest('form').submit();
  });

  var wheels = document.getElementById("js-internal-wheels");
  if (wheels) {
    wheels.addEventListener("click", function(){
      var left = document.querySelector(".internal-front-left-tire");
      var right = document.querySelector(".internal-front-right-tire");
      var left_checkbox  = left.querySelector("input[type='checkbox']");
      var right_checkbox = right.querySelector("input[type='checkbox']");
      if(left_checkbox.checked || right_checkbox.checked) {
        left_checkbox.checked = false;
        right_checkbox.checked = false;
      }
      left.classList.toggle('ppd-hide');
      right.classList.toggle('ppd-hide');
      update_checked_tire_count();
    });
  }
  var tires = document.querySelectorAll('.ppd-interventions-front-tires .pp-tire-input, .ppd-interventions-back-tires .pp-tire-input');
  if(tires.length) {
    for(var i = 0; i < tires.length; i++) {
      tires[i].addEventListener("click", update_checked_tire_count)
    }
  }

  // Gestion du champ "Référence BA" (formats et saisie)
  referenceBaField4 = document.querySelector('.ppd-custom-ba-field-4');
  referenceBaField5 = document.querySelector('.ppd-custom-ba-field-5');
  if (referenceBaField4) {
    var cleave1 = new Cleave('.ppd-custom-ba-field-4', {
      prefix: 'BA',
      noImmediatePrefix: true,
      delimiter: '-',
      numericOnly: true,
      blocks: [2, 4, 6],
      uppercase: true
    });
    referenceBaField4.addEventListener("click", function(e) {
      if (referenceBaField5) {
        referenceBaField5.value = null;
      }
    }, false);
  }
  if (referenceBaField5) {
    var cleave2 = new Cleave('.ppd-custom-ba-field-5', {
      prefix: 'BA',
      noImmediatePrefix: true,
      delimiter: '-',
      numericOnly: true,
      blocks: [2, 5, 6],
      uppercase: true
    });
    referenceBaField5.addEventListener("click", function(e) {
      if (referenceBaField4) {
        referenceBaField4.value = null;
      }
    }, false);
  }

});
