import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import 'bootstrap/dist/js/bootstrap';
import 'cleave.js/dist/cleave.min.js';
import './images.js';
import 'whatwg-fetch';
import '../src/javascripts/sites.js';
import '../src/javascripts/interventions.js';
import * as ActiveStorage from "@rails/activestorage";

Rails.start();
Turbolinks.start();
ActiveStorage.start();
