import * as autoComplete from 'javascript-autocomplete';

document.addEventListener("turbolinks:load", function() {
  new autoComplete({
      selector: '#site_agency',
      minChars: 2,
      source: function(term, suggest){
        fetch(`/agencies?q=${document.querySelector('#site_agency').value}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(function(response){
          return response.json();
        }).then(function(choices){
          suggest(choices);
        })
      },
      renderItem: function (item, search){
        return '<div class="autocomplete-suggestion" data-id="'+item['id']+'" data-val="' + item['id'] + ' - ' + item['name'] + '">'+ item['id'] + ' - ' + item['name']+'</div>';
      },
      onSelect: function(e, term, item){
        document.querySelector('#site_agency_id').value = item.dataset.id
      }
  });

  const import_sites_btn = document.getElementById('ppd-import-csv-button');
  if (import_sites_btn) {
    import_sites_btn.addEventListener('change', function () {
      this.form.submit();
    })
  }

  const import_sites_btn2 = document.querySelector('.ppd-import-csv');
  if (import_sites_btn2) {
    import_sites_btn2.addEventListener('click', function () {
      document.getElementById('ppd-import-csv-button').click();
    });
  }
})
